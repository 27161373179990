/**
 * !!! WARNING !!! IMPORTANT !!!
 *
 * Usage of this function is VERY DANGEROUS!
 * Modules loaded with this function will have access to all the globals from the current context,
 * which will give them full access to manipulate, inspect or subvert the currently running program.
 *
 * !!! ONLY LOAD TRUSTED CODE THIS WAY !!!
 *
 * @param module Source code consisting of a Universal Module Definition (https://github.com/umdjs/umd)
 * @returns Exported values from the module
 */
export function loadModuleFromString<T = any>(module: string): T {
  const _fn = new Function('module', 'exports', module);
  const _module = { exports: {} };
  _fn(_module, _module.exports);
  return _module.exports as T;
}
